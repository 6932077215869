import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Headshot from "../molecules/Headshot"

const StrategicAdvisors = () => (
  <section id="strategic-advisors" className="uk-section uk-section-secondary bg-dark-fuchsia uk-text-left">
    <div className="uk-container uk-container-large">
      <div data-uk-grid="true">

        <div className="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m uk-width-1-5@l">&nbsp;</div>
        <div className="uk-width-expand">

          <h2>Strategic Advisors</h2>
          <div data-uk-grid className="uk-flex-left uk-child-width-1-2 uk-child-width-1-6@s">
            <StaticQuery
              query={graphql`
                query StrategicAdvisors {
                  allStrategicadvisorsYaml {
                    nodes {
                      id
                      name
                      jobtitle
                      company
                      linkedin
                      headshot {
                        childImageSharp {
                          fluid(maxWidth: 180) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      logo {
                        childImageSharp {
                          fluid(maxWidth: 180) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => data.allStrategicadvisorsYaml.nodes.map((node, i) => (
                <>
                  <div key={node.id} className="uk-text-center">
                    <Headshot key={node.id} node={node} classes="" />
                  </div>
                </>
              ))}
            />
          </div>

        </div>

      </div>
    </div>
  </section>
)

export default StrategicAdvisors