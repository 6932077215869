import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Headshot from "../molecules/Headshot"
import styled from "styled-components"

const Bod = () => (
  <Section id="bod" className="uk-section uk-section-secondary bg-fuchsia uk-text-left uk-padding-remove-top uk-padding-remove-bottom">
    <div className="uk-container uk-container-large">
      <div data-uk-grid="true">

        <div className="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m uk-width-1-5@l">&nbsp;</div>
        <div className="uk-width-expand">

          <div data-uk-grid className="uk-flex-left uk-child-width-1-2 uk-child-width-1-6@s">
            <StaticQuery
              query={graphql`
                query Bod {
                  allBodYaml {
                    nodes {
                      id
                      name
                      jobtitle
                      company
                      linkedin
                      position
                      headshot {
                        childImageSharp {
                          fluid(maxWidth: 180) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      logo {
                        childImageSharp {
                          fluid(maxWidth: 180) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => data.allBodYaml.nodes.map((node, i) => (
                <>
                  <div key={node.id} className="uk-text-center">
                    <Headshot key={node.id} node={node} classes="" />
                  </div>
                  {/* {i === 2 && <FlexClearfix/>} */}
                </>
              ))}
            />
          </div>

        </div>

      </div>
    </div>
  </Section>
)

const Section = styled.div`
  @media screen and (min-width: 960px) and (max-width: 1179px) {
    margin-top: -90px;
  }
  @media screen and (min-width: 1180px) {
    margin-top: -200px;
  }
`

const FlexClearfix = styled.div`
  width: 100%;
`

export default Bod